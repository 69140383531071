 <template>
    <div class="rating_page">
        <section class="setname">
            <section class="setname-top" v-if="modify == 'username'">
                <div class="setname-input-container">
                    <input
                        type="text"
                        placeholder="输入用户名"
                        :class="{ 'setname-input': bordercolor }"
                        @input="inputThing"
                        v-model="inputValue"
                    />
                </div>
                <div>
                    <p>用户名（5-24字符之间）</p>
                </div>
            </section>
            <section class="setname-top" v-if="modify == 'phone'">
                <div class="setname-input-container">
                    <input
                        type="text"
                        placeholder="输入手机号"
                        :class="{ 'setname-input': bordercolor }"
                        @input="inputThing"
                        v-model="phoneValue"
                        maxlength="11"
                    />
                </div>
                <div>
                    <p>手机号（11个字符）</p>
                </div>
            </section>
            <section class="reset">
                <button :class="{ fontopacity: opacityall }" @click="resetName">
                    确认修改
                </button>
            </section>
        </section>
    </div>
</template>

<script>
import { setUserSetup } from "@/service";
import { getImgPath } from "@/components/common/mixin";
import { mapMutations, mapState } from "vuex";
export default {
    data() {
        return {
            earn: true, //输入框提醒
            bordercolor: false, //输入框边框颜色
            opacityall: false, //字体透明度
            inputValue: "", //输入框的内容
            newusername: "", //新用户名
            modify: "username",
            phoneValue: "",
        };
    },
    beforeMount() {
        const { modify = "username" } = this.$route.query;
        this.modify = modify;
    },
    created() {},
    mixins: [getImgPath],
    components: {},
    mounted() {},
    computed: {
        ...mapState(["userInfo"]),
    },
    props: [],
    methods: {
        ...mapMutations(["RETSET_NAME"]),
        async setUserSetup() {
            let params = {};
            this.phoneValue ? (params.phone = this.phoneValue) : null;
            this.inputValue ? (params.userName = this.inputValue) : null;
            const { data } = await setUserSetup(params);
            return true;
        },
        inputThing() {
            if (this.modify == "phone") {
                if (this.phoneValue.length != 11) {
                    this.opacityall = false;
                    return false;
                }
                return true;
            } else if (
                this.inputValue.length < 5 ||
                this.inputValue.length > 24
            ) {
                this.earn = false;
                this.opacityall = false;
                return false;
            } else {
                this.earn = true;
                this.opacityall = true;
                return true;
            }
        },
        resetName() {
            let checkResult = this.inputThing();
            if (!checkResult) {
                return;
            }
            this.setUserSetup();
            this.$nextTick(() => {
                this.modify == "username"
                    ? this.RETSET_NAME(this.inputValue)
                    : null;
                this.$router.go(-1);
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/mixin";

.rating_page {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 202;
    background: #fff;
    /* padding-top: 1.95rem; */
    p,
    span {
        font-family: Helvetica Neue, Tahoma, Arial;
    }
}
.setname {
    margin: 0 auto;
    padding: 0 60px;
    .setname-top {
        padding-top: 40px;
        input {
            background: none;
            width: 100%;
            border: 1px solid #ddd;
            @include borderRadius(2px);
            padding: 20px 10px;
            font-size: 28px;
            display: block;
            background: none;
            outline: none;
            border: none;
        }
        input:focus {
            outline: none;
            border: none;
        }
        .setname-input {
            border-color: #ea3106;
        }
        .setname-input-container {
            border: 1px solid #ddd;
            border-radius: 30px;
        }
        p {
            width: 100%;
            @include sc(0.4rem, #666);
            padding: 0.4rem 0 1rem;
        }
        .unlikep {
            @include sc(0.58rem, #ea3106);
            padding-top: 0.1rem;
        }
    }
    .reset {
        width: 100%;
        button {
            @include wh(100%, 100px);
            @include fj(center);
            align-items: center;
            background: #b72e32;
            border-radius: 72px;
            font-size: 32px;
            color: #ffffff;
        }
        .fontopacity {
            transition: all 1s;
            opacity: 1;
        }
    }
}
</style>
